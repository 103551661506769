$(function(){
	if( PROSKTR.content !== "product_info"){
		return true;
	}
	setViewedProduct();
	initStatistics();
	ajax_fetch_coupons('product');
	initMobileSlider();
	initReserve();

	function initReserve(){
		$('#address').on('click','.js-close-info',function(){
			var me = $(this);
			setTimeout(function(){
				me.closest('.j-info-shop-list-item').removeClass('active');
			},200);
		});
		$('#product-in-shops').on('click','.js-show-reserve-form',function(){
			var shopInfoHtml = $(this).closest('.j-info-shop-list-item').find('.js-shop-info').html();

			$('.js-shop-wrapper').html(shopInfoHtml);
		});
	}

	function setViewedProduct() {
		if ($('.js-product-data').length) {
			cookieObj.addViewedProduct($('.js-product-data').attr('data-products-id').toString());
		}
	}

	function initStatistics(){
		$('.js-product-info-send-statistics').click(function(){
			var isDisabled = $(this).prop('disabled');

			if(isDisabled){
				return true
			}

			var productName = $(this).data('product-name'),
				productId = $(this).data('product-id'),
				productBrand = $(this).data('product-brand'),
				productPrice = $(this).data('product-price'),
				productMainCategory = $(this).data('product-main-category'),
				productCategory = $(this).data('product-category');

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
			var dataStatistics = {
				currency: 'RUB',
				value: productPrice,
				items: [{
					item_name: productName,
					item_id: productId,
					price: productPrice,
					item_brand: productBrand,
					item_category: productMainCategory,
					item_category2: productCategory,
					item_category3: '',
					item_list_name: '',//�������� ������ �������
					item_list_id: '',// id ������ �������
					index: '',
					quantity: 1
				}]
			};
			dataLayer.push({
				event: 'add_to_cart',
				ecommerce: dataStatistics,
			});
		});
	}

	function initMobileSlider() {
		$('.j-product-gallery-slider').slick({
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			infinite: true,
			swipe: true,
			asNavFor: '.j-product-zoom-slider',
		});
		$('.j-product-zoom-slider').slick({
			arrows: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			infinite: true,
			swipe: false,
			asNavFor: '.j-product-gallery-slider',
		});
		$('#zoom-mobile-popup').change(function(){
			if($(this).prop('checked')){
				$('.j-product-zoom-slider').slick('refresh');
				$('.j-product-zoom-slider').find('.item').each(function (){
					$(this)[0].scrollTop = $(this)[0].scrollHeight/2 - window.innerHeight/2;
					$(this)[0].scrollLeft = $(this)[0].scrollWidth/2 - window.innerWidth/2;
				});
				$('.js-ow-product').addClass('mobile-zoom');
			}else{
				$('.js-ow-product').removeClass('mobile-zoom');
			}
		});
	}
});

function ajax_fetch_coupons( type ) {
	var data = {};
	if ( type == 'product' ) {
		if (  $('.js-add-product').length ) {
			data.products_id = $('.js-add-product').data('productId');
		} else {
			return;
		}
	} else {
		return;
	}
	data.action = 'recalc_price';
	let fullPrice = $('.j-full-price').data('price');
	$.ajax({
		type: "GET",
		url: "/ajax_fetch_coupons.php",
		data: data,
		dataType : "json",
		success: function( msg ) {
			if ( msg !== null ) {
				if ( msg['mindboxCashbackValue'] > 0 ) {
					$('.j-mindbox-cashback-holder').html('<dt>������ ����� �������: </dt><dd>' + msg['mindboxCashbackValue'] + ' ������' + ' (' + msg['mindboxCashbackPct'] + '%)</dd>');
				}
				if ( msg['MindboxBonusLimit'] > 0 ) {
					$('.j-mindbox-bonus-holder').html('<dt>������ �������: </dt><dd>' + msg['MindboxBonusLimit'] + ' ' + window.PROSKTR.currencies.logo + '</dd>');
				}
				$('.j-current-price').text(msg['price']);
				$('.j-partial-pay').text(Math.round(msg['price'] / 5));

				if ( msg['start_price'] > msg['price'] ) {
					$('.price-holder-no-discount').addClass('hide');
					$('.price-holder-discount').removeClass('hide');
				}
			}
		}
	});
}